<template>
    <v-container fluid >
        <v-row no-gutters class="d-flex justify-space-between">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card>
                    <v-toolbar color="cyan" dark dense>
                        <v-toolbar-title>Instrucciones para Estudiante</v-toolbar-title>
                    </v-toolbar>

                    <v-list >
                        <template v-for="(item, index) in instrucciones">

                            <v-divider
                                v-if="item.divider"
                                :key="index"
                                :inset="item.inset">
                            </v-divider>

                            <v-list-item :key="item.instruccion" >
                                <v-list-item-avatar size="28" color="info">
                                    <span class="white--text ">{{ index + 1 }}</span>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-html="item.instruccion"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Inicio",
    data() {
        return {
            instrucciones: [
                {
                    instruccion: 'Clic en el menú Cursos.',
                    divider: false,
                    inset: false
                },
                {
                    instruccion: 'Elija Mis Cursos > Aprobados, para ver la información.',
                    divider: true,
                    inset: true
                },
                {
                    instruccion: "Clic al botón <v-btn class='font-weight-bold info--text'>Descargar Certificado</v-btn> para generar el Certificado, Descargarlo e Imprimirlo.",
                    divider: true,
                    inset: true
                }
            ]
        }
    }
}
</script>

<style>

</style>
